import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./Home.css";
import {
  ParallaxProvider,
  Parallax,
  ParallaxBanner,
} from "react-scroll-parallax";
import GoogleMaps from "../Components/GoogleMaps/GoogleMaps";
import Slider from "../Components/Slider/Slider";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import Button from "../Elements/Button";
// import "./styles.css";
// import required modules
import { Pagination, Navigation } from "swiper";

const Home = (props) => {
  let [selectdestination, setDestination] = useState(null);
  const params = {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  };
  const destinations = {
    ORIGEN: {
      lat: 19.671138332800286,
      lng: -101.18245916021894,
    },
    HOSPITAL: {
      lat: 19.682668487706394,
      lng: -101.1917797312718,
    },
    ALTOZANO: {
      lat: 19.67384836408752,
      lng: -101.16368045992388,
    },
    UVAQ: {
      lat: 19.675625543311995,
      lng: -101.18128125658778,
    },
    TORRESKREA: {
      lat: 19.67086299199537,
      lng: -101.17803041576865,
    },
    VALLADOLID: {
      lat: 19.674961155624345,
      lng: -101.1833445628786,
    },
    CATEDRAL: {
      lat: 19.702422660444338,
      lng: -101.19231851349205,
    },
    PUERTAMADERO: {
      lat: 19.67177018368413,
      lng: -101.18178871564277,
    },
    SEVENDAYS: {
      lat: 19.672393691363666,
      lng: -101.1746263078519,
    },
  };
  const actualUrl = useLocation();
  //console.log(actualUrl)
  const history = useNavigate();
  const scrollTarget = (target) => {
    scroller.scrollTo(target, { smooth: true, duration: 800 });
  };

  const scrollToPage = (target) => {
    history(target);
    scrollTarget(target.slice(1));
  };

  useEffect(() => {
    scrollToPage(actualUrl.hash);
  }, []);
  //console.log(selectdestination,'destination')
  return (
    <>
      <ParallaxProvider>
        <div className="whatsapp-movil">
          <a href="https://api.whatsapp.com/send?phone=+524431699498&text=">
            <img
              src="/assets/Logotipos e iconos/svg-icon-whatsapp.svg"
              alt=""
            />
          </a>
        </div>
        <Stiky>
          <a onClick={() => props.setShowModal(true)}>DESCARGAR BROCHURE</a>
          <img
            onClick={() => props.setShowModal(true)}
            src="/assets/Logotipos e iconos/svg-icon-brochure.svg"
            alt=""
          />
        </Stiky>
        <Principal id="inicio">
          {/* <img src="/assets/Galeria/optimizadas/06.img-zankia-exterior4-min.jpg" alt="" /> */}
          <video
            loop
            muted
            autoPlay
            src="/assets/Home/optimizadas/hero.mp4"
          ></video>
          <Parallax
            speed={-10}
            translateX={[6, -10]}
            translateY={["-65vh", "-65vh"]}
          >
            <Logo>
              <img
                src="/assets/Logotipos e iconos/imagotipo_zalika.png"
                alt=""
              />
            </Logo>
          </Parallax>
          <div className="btn_prototipos">
            <a onClick={() => props.setShowModalP(true)}>VER PROTOTIPOS</a>
          </div>
        </Principal>
        <Concepto id="concepto">
          <div className="titulo wow animate__animated animate__fadeInDown">
            <h1>BIENVENIDO</h1>
          </div>
          <div className="texto wow animate__animated animate__fadeIn">
            <p>
              Un lugar en el que la armonía y el equilibrio entre la familia y
              el entorno, lo son todo. Un concepto que logra conectar en armonía
              a la familia, su seguridad, la naturaleza, la arquitectura y
              diseño detallado, y así, formar un hogar en el que se respire paz
              y equilibrio.
            </p>
            <Button href="/disponibilidad" primary>
              VER DISPONIBILIDAD
            </Button>
          </div>
        </Concepto>
        <Video>
          <h1>La grandeza de un Hogar en equilibrio</h1>
          <video
            loop
            muted
            autoPlay
            src="/assets/Home/optimizadas/production ID_4628803.mp4"
          ></video>
        </Video>
        <Prototipos>
          <div className="titulo wow animate__animated animate__fadeInDown">
            <h1>PROTOTIPOS</h1>
          </div>
          <div className="subtitulo wow animate__animated animate__fadeIn">
            <h1>4</h1>
            <div>
              <p>modelos</p>
              <p>
                desde 157.7m<sup>2</sup>
              </p>
            </div>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="prototipo">
                <div className="fachada">
                  <img
                    src="/assets/Prototipos/01.Zazil/optimizadas/ProT01.jpg"
                    alt=""
                  />
                </div>
                <div className="detalles">
                  <div className="detalles_center">
                    <img src="/assets/Prototipos/SVG/zazil.svg" alt="" />
                    <p>
                      Lote tipo 6.50 m x 18 m <br /> 157.7 m<sup>2</sup> de
                      construcción
                    </p>
                    <div className="plantas">
                      <span className="activo">Planta Baja</span>{" "}
                      <span>Planta Alta</span>
                    </div>
                    <div className="detalles_lista">
                      <ul>
                        <li>Sala</li>
                        <li>Comedor</li>
                        <li>Cocina integral</li>
                        <li>Barra desayunadora</li>
                        <li>1 Recamara</li>
                        <li>1 Baño</li>
                        <li>Patio de servicio</li>
                        <li>Jardín trasero</li>
                        <li>Dos espacios de cochera</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="planta">
                  <img
                    src="/assets/Prototipos/01.Zazil/optimizadas/cocina.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="ocultar">
              <div className="prototipo">
                <div className="fachada">
                  <img
                    src="/assets/Prototipos/01.Zazil/optimizadas/01.img-ext-zazil-min.jpg"
                    alt=""
                  />
                </div>
                <div className="detalles">
                  <div className="detalles_center">
                    <img src="/assets/Prototipos/SVG/zazil.svg" alt="" />
                    <p>
                      Lote tipo 6.50 m x 18 m <br /> 157.7 m<sup>2</sup> de
                      construcción
                    </p>
                    <div className="plantas">
                      <span>Planta Baja</span>{" "}
                      <span className="activo">Planta Alta</span>
                    </div>
                    <div className="detalles_lista">
                      <ul className="width-full">
                        <li>3 Recámaras</li>
                        <li>1 Vestidor</li>
                        <li>2 Baños</li>
                        <li>Calentador solar</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="planta">
                  <img
                    src="/assets/Prototipos/01.Zazil/optimizadas/complt.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="prototipo">
                <div className="fachada">
                  <img
                    src="/assets/Prototipos/02.Zinia/optimizadas/01.img-ext-zinia-min.jpg"
                    alt=""
                  />
                </div>
                <div className="detalles">
                  <div className="detalles_center">
                    <img src="/assets/Prototipos/SVG/zinia.svg" alt="" />
                    <p>
                      Lote tipo 6.50 m x 18 m <br /> 178.34 m<sup>2</sup> de
                      construcción
                    </p>
                    <div className="plantas">
                      <span className="activo">Planta Baja</span>{" "}
                      <span>Planta Alta</span>
                    </div>
                    <div className="detalles_lista">
                      <ul>
                        <li>Sala</li>
                        <li>Comedor</li>
                        <li>Cocina integral</li>
                        <li>Barra desayunadora</li>
                        <li>Patio de servicio</li>
                        <li>1/2 Baño</li>
                        <li>Jardín trasero</li>
                        <li>Dos espacios de cochera</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="planta">
                  <img
                    src="/assets/Prototipos/02.Zinia/optimizadas/cocina.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="ocultar">
              <div className="prototipo">
                <div className="fachada">
                  <img
                    src="/assets/Prototipos/02.Zinia/optimizadas/01.img-ext-zinia-min.jpg"
                    alt=""
                  />
                </div>
                <div className="detalles">
                  <div className="detalles_center">
                    <img src="/assets/Prototipos/SVG/zinia.svg" alt="" />
                    <p>
                      Lote tipo 6.50 m x 18 m <br /> 178.34 m<sup>2</sup> de
                      construcción
                    </p>
                    <div className="plantas">
                      <span>Planta Baja</span>{" "}
                      <span className="activo">Planta Alta</span>
                    </div>
                    <div className="detalles_lista">
                      <ul className="width-full">
                        <li>3 Recámaras</li>
                        <li>1 Vestidor</li>
                        <li>3 Baños</li>
                        <li>1 Balcón</li>
                        <li>Calentador solar</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="planta">
                  <img
                    src="/assets/Prototipos/02.Zinia/optimizadas/cmplt.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="prototipo">
                <div className="fachada">
                  <img
                    src="/assets/Prototipos/03.Zury/optimizadas/01.img-ext-zury-min.jpg"
                    alt=""
                  />
                </div>
                <div className="detalles">
                  <div className="detalles_center">
                    <img src="/assets/Prototipos/SVG/zury.svg" alt="" />
                    <p>
                      Lote tipo 6.50 m x 18 m <br /> 160.97m<sup>2</sup> de
                      construcción
                    </p>
                    <div className="plantas">
                      <span className="activo">Planta Baja</span>{" "}
                      <span>Planta Alta</span>
                    </div>
                    <div className="detalles_lista">
                      <ul>
                        <li>Sala</li>
                        <li>Comedor</li>
                        <li>Cocina integral</li>
                        <li>Barra desayunadora</li>
                        <li>Patio de servicio</li>
                        <li>1/2 Baño</li>
                        <li>Jardín trasero</li>
                        <li>Dos espacios de cochera</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="planta">
                  <img
                    src="/assets/Prototipos/03.Zury/optimizadas/cocina.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="ocultar">
              <div className="prototipo">
                <div className="fachada">
                  <img
                    src="/assets/Prototipos/03.Zury/optimizadas/01.img-ext-zury-min.jpg"
                    alt=""
                  />
                </div>
                <div className="detalles">
                  <div className="detalles_center">
                    <img src="/assets/Prototipos/SVG/zury.svg" alt="" />
                    <p>
                      Lote tipo 6.50 m x 18 m <br />
                      160.97m<sup>2</sup> de construcción
                    </p>
                    <div className="plantas">
                      <span>Planta Baja</span>{" "}
                      <span className="activo">Planta Alta</span>
                    </div>
                    <div className="detalles_lista">
                      <ul className="width-full">
                        <li>3 Recámaras</li>
                        <li>1 Vestidor</li>
                        <li>2 Baños</li>
                        <li>Calentador solar</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="planta">
                  <img
                    src="/assets/Prototipos/03.Zury/optimizadas/cmplt.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="prototipo">
                <div className="fachada">
                  <img
                    src="/assets/Prototipos/04.Zazu/optimizadas/zazu.jpeg"
                    alt=""
                  />
                </div>
                <div className="detalles">
                  <div className="detalles_center">
                    <img src="/assets/Prototipos/SVG/zazu.svg" alt="" />
                    <p className="padding-metros">
                      Lote tipo 9.25 m x 18 m <br /> 259.1 m<sup>2</sup> de
                      construcción
                    </p>
                    <div className="plantas min-width">
                      <span className="activo">Planta Baja</span>{" "}
                      <span>Planta Alta</span>
                      <span>Azotea</span>
                    </div>
                    <div className="detalles_lista">
                      <ul>
                        <li>Sala</li>
                        <li>Comedor</li>
                        <li>Cocina integral</li>
                        <li>Barra desayunadora</li>
                        <li>1 Recámara</li>
                        <li>Patio de servicio</li>
                        <li>1 1/2 Baño</li>
                        <li>2 Jardines</li>
                        <li>Dos espacios de cochera</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="planta">
                  <img
                    src="/assets/Prototipos/04.Zazu/optimizadas/cocina.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="ocultar">
              <div className="prototipo">
                <div className="fachada">
                  <img
                    src="/assets/Prototipos/04.Zazu/optimizadas/zazu.jpeg"
                    alt=""
                  />
                </div>
                <div className="detalles">
                  <div className="detalles_center">
                    <img src="/assets/Prototipos/SVG/zazu.svg" alt="" />
                    <p className="padding-metros">
                      Lote tipo 9.25 m x 18 m <br /> 259.1 m<sup>2</sup> de
                      construcción
                    </p>
                    <div className="plantas min-width">
                      <span>Planta Baja</span>{" "}
                      <span className="activo">Planta Alta</span>
                      <span>Azotea</span>
                    </div>
                    <div className="detalles_lista bottom-zazu-1">
                      <ul className="width-full">
                        <li>3 Recámaras</li>
                        <li>1 Vestidor</li>
                        <li>Sala de TV</li>
                        <li>3 Baños</li>
                        <li>1 Balcón</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="planta">
                  <img
                    src="/assets/Prototipos/04.Zazu/optimizadas/cmplt.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="ocultar">
              <div className="prototipo">
                <div className="fachada">
                  <img
                    src="/assets/Prototipos/04.Zazu/optimizadas/zazu.jpeg"
                    alt=""
                  />
                </div>
                <div className="detalles">
                  <div className="detalles_center">
                    <img src="/assets/Prototipos/SVG/zazu.svg" alt="" />
                    <p className="padding-metros">
                      Lote tipo 9.25 m x 18 m <br /> 259.1 m<sup>2</sup> de
                      construcción
                    </p>
                    <div className="plantas min-width">
                      <span>Planta Baja</span> <span>Planta Alta</span>
                      <span className="activo">Azotea</span>
                    </div>
                    <div className="detalles_lista bottom-zazu-2">
                      <ul className="width-full ">
                        <li>Roof garden</li>
                        <li>1/2 Baño</li>
                        <li>Calentador solar</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="planta">
                  <img
                    src="/assets/Prototipos/04.Zazu/optimizadas/09.zazu.-3-min.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <Button href="/disponibilidad" primary>
            VER DISPONIBILIDAD
          </Button>
        </Prototipos>
        <Amenidades id="amenidades">
          <div className="amenidades_contenedor">
            {/* <img className='grafico' src="/assets/Logotipos e iconos/svg.iso.back.zalika.svg" alt="" /> */}
            <div className="titulo  wow animate__animated animate__fadeInDown">
              <h1>AMENIDADES</h1>
            </div>
            <div className="amenidades">
              <div className="amenidades_list">
                <div className="icono wow animate__animated animate__fadeIn">
                  <p>Acceso controlado</p>
                  <img
                    src="/assets/Logotipos e iconos/icon_svg_acceso_controlado.svg"
                    alt=""
                  />
                </div>
                <div className="icono wow animate__animated animate__fadeIn ">
                  <p>Vigilancia 24 hrs</p>
                  <img
                    src="/assets/Logotipos e iconos/icon_svg_vigilancia.svg"
                    alt=""
                  />
                </div>
                <div className="icono wow animate__animated animate__fadeIn ">
                  <p>Trotapista</p>
                  <img
                    src="/assets/Logotipos e iconos/icon_svg_trotapista.svg"
                    alt=""
                  />
                </div>
                <div className="icono wow animate__animated animate__fadeIn ">
                  <p>Gimnasio exterior</p>
                  <img
                    src="/assets/Logotipos e iconos/icon_svg_gym_exterior.svg"
                    alt=""
                  />
                </div>
                <div className="icono wow animate__animated animate__fadeIn ">
                  <p>Arenero</p>
                  <img
                    src="/assets/Logotipos e iconos/icon_svg_arenero.svg"
                    alt=""
                  />
                </div>
                <div className="icono wow animate__animated animate__fadeIn ">
                  <p>Juegos infantiles</p>
                  <img
                    src="/assets/Logotipos e iconos/icon_svg_juegos.svg"
                    alt=""
                  />
                </div>
                <div className="icono wow animate__animated animate__fadeIn ">
                  <p>Áreas verdes</p>
                  <img
                    src="/assets/Logotipos e iconos/icon_svg_verdes.svg"
                    alt=""
                  />
                </div>
                <div className="icono wow animate__animated animate__fadeIn ">
                  <p>Fogatero</p>
                  <img
                    src="/assets/Logotipos e iconos/icon_svg_fogatero.svg"
                    alt=""
                  />
                </div>
                <div className="icono wow animate__animated animate__fadeIn ">
                  <p>Camellones centrales</p>
                  <img
                    src="/assets/Logotipos e iconos/icon_svg_camellones.svg"
                    alt=""
                  />
                </div>
                <div className="icono2 wow animate__animated animate__fadeIn ">
                  <p>Terraza</p>
                  <img
                    src="/assets/Logotipos e iconos/icono_blanco_terraza.svg"
                    alt=""
                  />
                </div>
              </div>
              <p>
                Disfrutarás de un ambiente seguro para ti y tu familia, gracias
                a un acceso controlado.
              </p>
              <div className="amenidades_button">
                <Button href="/disponibilidad">VER DISPONIBILIDAD</Button>
              </div>
            </div>
          </div>
        </Amenidades>
        <Imagen padding>
          <ParallaxBanner
            layers={[
              {
                image: "/assets/Home/optimizadas/img-exterior.h-min.jpg",
                speed: -15,
              },
            ]}
            className="img_parallax"
          />
          {/* <img src="/assets/Home/optimizadas/img-exterior.h-min.jpg" alt="" /> */}
        </Imagen>
        <Galeria>
          <div className="galerias">
            <div className="galerias_top">
              <p className=" wow animate__animated animate__fadeInDown">
                ZÁLIKA ofrece un estilo de vida en completo equilibrio, por lo
                que proporciona un área verde de 5,418.01 m2 y amenidades
              </p>
              <p className=" wow animate__animated animate__fadeIn">
                Disfrutando de su excelente distribución y camellones llenos de
                vida.
              </p>
              <Button href="/disponibilidad" primary>
                VER DISPONIBILIDAD
              </Button>
            </div>
            <img
              className="fondo-galeria"
              src="/assets/Logotipos e iconos/svg.iso.back.zalikagris.svg"
              alt=""
            />
          </div>
          <div className="carousel">
            <Slider></Slider>
          </div>
        </Galeria>
        <Imagen padding className="dispo">
          <div className="transparent-black"></div>
          <img src="/assets/Home/optimizadas/img-exteriores-min.jpg" alt="" />
          <div className="zalika_diponibilidad">
            <img
              className="icono  wow animate__animated animate__fadeInDown"
              src="/assets/Logotipos e iconos/logotipo_blanco.png"
              alt=""
            />
            <p className=" wow animate__animated animate__fadeInDown animate__delay-1s">
              Un concepto que logra conectar en armonía a la familia, su
              seguridad, la naturaleza, la arquitectura y diseño detallado, para
              así, formar un hogar en el que se respire paz y equilibrio.
            </p>
            <Button href="/disponibilidad" primary>
              VER DISPONIBILIDAD
            </Button>
          </div>
        </Imagen>
        <Ubicacion id="ubicacion">
          <div className="ubicacion">
            <div className="titulo wow animate__animated animate__fadeInDown">
              <h1>UBICACION</h1>
            </div>
            <div className="iconos_maps">
              <a target={"_blank"} href="https://goo.gl/maps/jhTBHaYThR1j3Vhi9">
                <img
                  src="/assets/Logotipos e iconos/svg-icon-maps.svg"
                  alt=""
                />
              </a>
              <a
                target={"_blank"}
                href="https://www.waze.com/en/live-map/directions/mx/mich./morelia/antonio-plaza?place=EjpBbnRvbmlvIFBsLiwgTGFzIFRvcnJlY2lsbGFzLCA1ODA5NiBNb3JlbGlhLCBNaWNoLiwgTWV4aWNvIi4qLAoUChIJp3r0p-oNLYQRE2b31_GMylYSFAoSCfcMW1SVDS2EEUSMgMwgwSvy"
              >
                <img
                  src="/assets/Logotipos e iconos/svg-icon-waze.svg"
                  alt=""
                />
              </a>
            </div>
            <a target={"_blank"} href="tel:4432984527" className="tel">
              T. 443 298 4527
            </a>
            <p className="address">
              Antonio plaza #825 ,Col santa Maria de guido. CP. 58090, Morelia
              Mich
            </p>
            <div className="location-poi">
              <div className="location-poi-items">
                <span
                  className="item"
                  onClick={() => setDestination(destinations.HOSPITAL)}
                >
                  <li>Hospital Star Médica</li>
                </span>
                <span
                  className="item"
                  onClick={() => setDestination(destinations.PUERTAMADERO)}
                >
                  <li>Puerta Madero</li>
                </span>
                <span
                  className="item"
                  onClick={() => setDestination(destinations.ALTOZANO)}
                >
                  <li>Plaza Altozano</li>
                </span>
                <span
                  className="item"
                  onClick={() => setDestination(destinations.TORRESKREA)}
                >
                  <li>Torres Krea</li>
                </span>
                <span
                  className="item"
                  onClick={() => setDestination(destinations.UVAQ)}
                >
                  <li>UVAQ</li>
                </span>
                <span
                  className="item"
                  onClick={() => setDestination(destinations.SEVENDAYS)}
                >
                  <li>Seven days</li>
                </span>
                <span
                  className="item"
                  onClick={() => setDestination(destinations.VALLADOLID)}
                >
                  <li>Universidad Valladolid</li>
                </span>
                <span
                  className="item"
                  onClick={() => setDestination(destinations.CATEDRAL)}
                >
                  <li>Catedral centro historico</li>
                </span>
              </div>
            </div>
          </div>
          <div className="mapa">
            <GoogleMaps
              origen={destinations.ORIGEN}
              destination={selectdestination}
            ></GoogleMaps>
          </div>
        </Ubicacion>
      </ParallaxProvider>
    </>
  );
};
const Stiky = styled.div`
  position: fixed;
  z-index: 9;
  bottom: 0;
  left: 100px;
  background-color: #8a9e77;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 1024px) {
    right: 0;
    top: 50%;
    bottom: auto;
    left: auto;
    width: 70px;
    height: 60px;
    background-color: #634f42;
  }
  @media (max-width: 550px) {
    width: 60px;
    height: 50px;
    top: 48%;
  }
  a {
    color: white;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  img {
    display: none;
    @media (max-width: 1024px) {
      display: flex;
    }
    @media (max-width: 550px) {
      width: 30px;
    }
  }
  &:hover {
    background-color: #c2b0a1;
  }
`;
// const Button = styled.a`
//     background-color: ${props => props.primary ? "#8A9E77" : "#94784F"};
//     color: white;
//     text-decoration: none;
//     padding: 10px 20px;
//     cursor: pointer;
//     font-family: "Julius Sans One, Regular";
//     font-size: 18px;
//     @media (max-width:550px) {
//         font-size: 16px;
//     }
//     &:hover{
//         background-color: ${props => props.primary ? "#C2B0A1" : "#C2B0A1"};
//     }
// `
const Principal = styled.div`
  width: 100%;
  height: 100vh;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Logo = styled.div`
  width: 320px;
  height: 280px;
  position: absolute;
  left: 0;
  background-color: rgba(255, 255, 255, 0.69);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 1600px) {
    width: 400px;
    height: 380px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
  img {
    width: 85%;
    height: 100%;
    object-fit: contain;
  }
`;
const Concepto = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  background-image: url("/assets/Logotipos e iconos/svg.iso.back.zalika.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media (max-width: 1024px) {
    background-position: right;
    background-size: auto;
    flex-direction: column;
  }
  @media (max-width: 550px) {
    background-position: bottom;
    background-size: cover;
    height: 70vh;
    min-height: 600px;
  }
  .titulo {
    width: 40%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #634f42;
    @media (max-width: 1024px) {
      width: 100%;
      height: 40%;
    }
    @media (max-width: 550px) {
      height: 20%;
      align-items: flex-end;
    }
    h1 {
      font-size: 45px;
      @media (max-width: 550px) {
        font-size: 35px;
      }
    }
  }
  .texto {
    width: 60%;
    height: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    @media (max-width: 1024px) {
      width: 100%;
      height: 60%;
      align-items: center;
      justify-content: flex-start;
    }
    @media (max-width: 550px) {
      height: 80%;
    }
    p {
      width: 70%;
      color: #634f42;
      font-size: 18px;
      @media (max-width: 1024px) {
        margin: 90px 0;
        width: 60%;
        text-align: center;
      }
      @media (max-width: 550px) {
        width: 80%;
        font-size: 16px;
      }
    }
  }
`;
const Prototipos = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 30px;
  @media (max-width: 550px) {
    height: 100vh;
    min-height: 800px;
  }
  .titulo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #634f42;
    height: 200px;
    @media (max-width: 550px) {
      height: 100px;
    }
    h1 {
      font-size: 45px;
      @media (max-width: 550px) {
        font-size: 35px;
      }
    }
  }
  .subtitulo {
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 142px;
      color: #8a9e77;
      margin-right: 10px;
      @media (max-width: 550px) {
        font-size: 100px;
      }
    }
    div {
      p {
        color: #8a9e77;
        font-size: 30px;
        @media (max-width: 550px) {
          font-size: 20px;
        }
        &:nth-child(1) {
          font-size: 90px;
          line-height: 45px;
          @media (max-width: 550px) {
            font-size: 50px;
            line-height: 30px;
          }
        }
      }
    }
  }
  .swiper {
    width: 100%;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    color: #634f42;
  }
  .prototipo {
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: 30% 20% 50%;
    @media (max-width: 1024px) {
      grid-template-columns: 100%;
      grid-template-rows: 70% 30%;
    }
    .fachada {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (max-width: 1024px) {
        justify-content: center;
      }

      img {
        width: 85%;
        height: 450px;
        object-fit: cover;
        @media (max-width: 1024px) {
          object-fit: contain;
        }
        @media (max-width: 550px) {
          height: 100%;
        }
      }
    }
    .detalles {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 50px;
      position: relative;
      @media (max-width: 1024px) {
        padding-left: 0;
      }
      @media (max-width: 550px) {
        padding-left: 0;
        height: 100px;
      }
      .detalles_center {
        position: relative;
        width: 100%;
        height: 450px;
        @media (max-width: 1024px) {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px 0;
          margin-bottom: 30px;
        }
        p {
          font-family: "Catamaran, SemiBold";
          color: #634f42;
          font-size: 18px;
          width: 100%;
          padding: 30px 0;
          @media (max-width: 1024px) {
            width: auto;
            text-align: center;
          }
        }
        .padding-metros {
          padding: 40px 0;
          @media (max-width: 550px) {
            padding: 30px 0;
          }
        }
        img {
          width: 200px;
          @media (max-width: 550px) {
            width: 130px;
          }
        }
        .plantas {
          width: 100%;
          display: flex;
          justify-content: space-between;
          @media (max-width: 1024px) {
            display: none;
          }
          span {
            font-size: 18px;
            color: #634f42;
            font-family: "Catamaran, SemiBold";
          }
          .activo {
            border-bottom: 2px solid #634f42;
          }
        }
        .min-width {
          min-width: 290px;
        }
        .detalles_lista {
          width: 450px;
          padding-left: 20px;
          position: absolute;
          bottom: 15px;
          @media (max-width: 1024px) {
            display: none;
          }
          ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            li {
              width: 50%;
              color: #634f42;
              font-size: 17px;
            }
          }
          .width-full {
            flex-wrap: nowrap;
            flex-direction: column;
          }
          &.bottom-zazu-1 {
            bottom: 5px;
          }
          &.bottom-zazu-2 {
            bottom: 50px;
          }
        }
      }
    }
    .planta {
      width: 100%;
      height: 100%;
      @media (max-width: 1024px) {
        display: none;
      }
      img {
        width: 75%;
        height: auto;
        object-fit: contain;
      }
    }
  }
`;
const Amenidades = styled.div`
  width: 100%;
  height: 100vh;
  padding: 80px 30px 20px 30px;
  min-height: 900px;
  @media (min-width: 1600px) {
    min-height: 1000px;
  }
  @media (max-width: 550px) {
    padding: 20px 0;
    height: 140vh;
    min-height: 1150px;
  }
  .amenidades_contenedor {
    width: 100%;
    height: 100%;
    background-color: #8a9e77;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    .grafico {
      width: 700px;
      position: absolute;
      top: -50;
      left: -110px;
    }
    .titulo {
      width: 40%;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      @media (max-width: 1024px) {
        width: 100%;
        height: 30%;
      }
      @media (max-width: 550px) {
        height: 15%;
        align-items: center;
      }
      h1 {
        font-size: 45px;
        @media (max-width: 550px) {
          font-size: 35px;
        }
      }
    }
    .amenidades {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      @media (max-width: 1024px) {
        width: 100%;
        height: 70%;
        align-items: center;
      }
      @media (max-width: 550px) {
        height: 85%;
        justify-content: space-between;
      }
      &_list {
        display: flex;
        width: 90%;
        height: 350px;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
        margin-top: 50px;
        @media (min-width: 1600px) {
          height: 475px;
          width: 85%;
          align-items: center;
        }
        @media (max-width: 550px) {
          align-items: center;
          height: 675px;
          margin-top: 0;
        }
        @media (max-width: 375px) {
          height: 710px;
        }
        .icono {
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-around;
          align-items: center;
          height: 110px;
          width: 18%;
          &:nth-child(5) {
            img {
              width: 55%;
            }
          }
          &:nth-child(10) {
            img {
              width: 50%;
            }
          }
          @media (min-width: 1600px) {
            height: 160px;
          }
          @media (max-width: 550px) {
            width: 45%;
            justify-content: space-evenly;
          }
          img {
            width: 70%;
            height: 70px;
            object-fit: contain;
            @media (min-width: 1600px) {
              width: 75%;
            }
          }
          p {
            color: white;
            width: 100%;
            text-align: center;
            margin-top: 10px;
            margin-left: 0;
          }
        }
        .icono2 {
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-around;
          align-items: center;
          height: 110px;
          width: 18%;
          @media (max-width: 550px) {
            margin-top: 40px;
          }
          &:nth-child(5) {
            img {
              width: 55%;
            }
          }
          &:nth-child(10) {
            img {
              width: 100%;
            }
          }
          @media (min-width: 1600px) {
            height: 140px;
          }
          @media (max-width: 550px) {
            width: 45%;
            height: 130px;
            justify-content: space-evenly;
          }
          img {
            width: 100%;
            height: 160px;
            object-fit: contain;
            @media (min-width: 1600px) {
              width: 95%;
            }
            @media (min-width: 375px) {
              width: 85%;
            }
          }
          p {
            color: white;
            width: 100%;
            text-align: center;
            margin-top: 0px;
            margin-left: 0;
          }
        }
      }
      &_button {
        display: flex;
        justify-content: center;
        width: 75%;
        @media (max-width: 550px) {
          margin-bottom: 50px;
        }
      }
      p {
        color: white;
        width: 80%;
        font-size: 18px;
        margin-left: 50px;
        @media (min-width: 1600px) {
          width: 70%;
        }
        @media (max-width: 550px) {
          text-align: center;
          font-size: 16px;
          margin-left: 0;
        }
      }
    }
  }
`;
const Video = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;
  h1 {
    font-size: 50px;
    text-align: center;
    height: 55px;
    color: white;
    font-family: "Julius Sans One, Regular";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    @media (max-width: 550px) {
      width: 90%;
      font-size: 25px;
      text-align: center;
    }
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Imagen = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.padding ? "0px 30px" : "0px")};
  position: relative;
  @media (max-width: 550px) {
    height: 40vh;
    padding: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img_parallax {
    height: 100%;
  }
  .transparent-black {
    width: 96%;
    height: 100%;
    position: absolute;
    background-color: black;
    opacity: 0.4;
    @media (min-width: 1600px) {
      width: 97%;
    }
    @media (max-width: 1024px) {
      width: 94%;
    }
    @media (max-width: 880px) {
      width: 93%;
    }
    @media (max-width: 550px) {
      display: none;
    }
  }
  .zalika_diponibilidad {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 450px;
    @media (max-width: 1024px) {
      height: 50%;
    }
    @media (max-width: 550px) {
      height: 81%;
      bottom: 0;
      justify-content: space-between;
    }
    .icono {
      width: 270px;
      height: auto;
    }
    p {
      color: white;
      width: 60%;
      text-align: center;
    }
    a {
    }
  }
  &.dispo {
    @media (max-width: 550px) {
      height: 60vh;
      align-items: flex-start;
    }
    img {
      @media (max-width: 550px) {
        height: 50%;
        padding: 0 30px;
        object-fit: contain;
      }
    }
    p {
      margin-top: 50px;
      @media (max-width: 550px) {
        width: 80%;
        color: #634f42;
        margin-top: 10px;
      }
    }
  }
`;
const Galeria = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 130vh;
  padding: 0 30px;
  position: relative;
  overflow: hidden;
  @media (max-width: 1024px) {
    height: 80vh;
  }
  @media (max-width: 550px) {
    padding: 0;
  }
  .galerias {
    width: 100%;
    height: 80%;
    background-color: #c2b0a1;
    position: relative;
    display: flex;
    justify-content: center;
    @media (max-width: 1024px) {
      /* height: 60%; */
    }
    .fondo-galeria {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &_top {
      position: absolute;
      width: 50%;
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 50px;
      @media (max-width: 1024px) {
        width: 80%;
        height: 95%;
      }
      @media (max-width: 550px) {
        width: 90%;
        height: 80%;
      }
      p {
        color: white;
        font-size: 29px;
        text-align: center;
        font-family: "Julius Sans One, Regular";
        @media (max-width: 550px) {
          font-size: 16px;
        }
        &:nth-child(2) {
          font-size: 18px;
          font-family: "Catamaran, Regular";
          @media (min-width: 1600px) {
            max-width: 700px;
          }
          @media (max-width: 550px) {
            font-size: 15px;
          }
        }
      }
    }
  }
  .carousel {
    height: 45%;
    position: absolute;
    width: 100%;
    bottom: 0;
    @media (max-width: 1024px) {
      height: 30%;
    }
  }
`;
const Ubicacion = styled.div`
  width: 100%;
  height: 700px;
  display: flex;
  padding-top: 60px;
  @media (max-width: 1024px) {
    flex-direction: column;
    height: 100vh;
  }
  @media (max-width: 550px) {
    height: 130vh;
    min-height: 1000px;
  }
  .ubicacion {
    width: 35%;
    height: 100%;
    background-color: #c2b0a1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
      width: 100%;
      height: 50%;
    }
    .titulo {
      color: #634f42;
    }
    .iconos_maps {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      img {
        width: 130px;
        &:nth-child(2) {
          width: 100px;
        }
      }
    }
    .tel {
      font-family: "Catamaran, SemiBold";
      font-size: 21px;
      color: #634f42;
      height: 50px;
      text-decoration: none;
    }
    .address {
      font-family: "Julius Sans One, Regular";
      color: #94784f;
      text-align: center;
      width: 80%;
      height: 50px;
      display: flex;
      align-items: center;
    }
    .location-poi {
      width: 90%;
      height: 30%;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      @media (max-width: 1024px) {
        width: 100%;
      }
      &-items {
        padding-left: 90px;
        font-size: 1.2em;
        flex-wrap: wrap;
        column-gap: 10px;
        display: flex;
        @media (max-width: 1024px) {
          justify-content: flex-end;
        }
        @media (max-width: 450px) {
          padding-left: 17px;
        }
        .item {
          display: flex;
          width: 45%;
          font-weight: 900;
          color: #634f42;
          font-size: 15px;
          cursor: pointer;
          transition: transform 0.3s;
          &:hover {
            transform: translateX(3%);
          }
          &.active {
            transform: translateX(0) !important;
            &::before {
              display: inline-block;
            }
          }
          &:not(:last-child) {
            margin-bottom: 5px;
            @media (max-width: 450px) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  .mapa {
    width: 65%;
    height: 100%;
    @media (max-width: 1024px) {
      width: 100%;
      height: 50%;
    }
  }
`;
export default Home;
