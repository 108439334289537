import styled from 'styled-components'

const Button = styled.a`
    background-color: ${props => props.primary ? "#8A9E77" : "#94784F"};
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    cursor: pointer;
    font-family: "Julius Sans One, Regular";
    font-size: 18px;
    @media (max-width:550px) {
        font-size: 16px;
    }
    &:hover{
        background-color: ${props => props.primary ? "#C2B0A1" : "#C2B0A1"};
    }
`

export default Button;