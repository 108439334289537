import Header from "./Components/Header/Header";
import Home from "./Pages/Home";
import WOW from "wowjs"
import 'animate.css'
import { useEffect, useState } from "react";
import Footer from "./Components/Footer/Footer";
import Formulario from "./Components/Modal/Formulario";
import Prototipos from "./Components/Modal/Prototipos";
import Galeria from "./Components/Modal/Galeria";
import { CSSTransition } from "react-transition-group";
function App() {
  const [showModal, setShowModal] = useState(false)
  const [showModalP, setShowModalP] = useState(false)
  const [showModalG, setShowModalG] = useState(false)
  const [open, setOpen] = useState('');
  
  useEffect(()=>{
    new WOW.WOW({
      live:false
    }).init();
  },[]);

  return (
    <div className={`App ${open}`}>
      <CSSTransition
            in={showModal}
            timeout={200}
            classNames="desliz"
            unmountOnExit
      >
        <Formulario 
          showModal= {showModal}
          setShowModal = {setShowModal}
        />
      </CSSTransition>
      <CSSTransition
            in={showModalP}
            timeout={200}
            classNames="show"
            unmountOnExit
      >
        <Prototipos 
          showModal= {showModalP}
          setShowModal = {setShowModalP}
        />
      </CSSTransition>
      <CSSTransition
            in={showModalG}
            timeout={200}
            classNames="show"
            unmountOnExit
      >
        <Galeria 
          showModal= {showModalG}
          setShowModal = {setShowModalG}
        />
      </CSSTransition>
        <Header 
         open={open}
         setOpen={setOpen}
         setShowModalG={setShowModalG}
        />
        <Home 
          showModal= {showModal}
          setShowModal = {setShowModal}
          setShowModalP = {setShowModalP}
        />
        <Footer></Footer>
    </div>
  );
}

export default App;
