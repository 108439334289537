import React, { useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsService,DirectionsRenderer } from '@react-google-maps/api';
/* global google */

const containerStyle = {
    width: '100%',
    height: '100%'
  };
  
const center = {  
    lat: 19.671138332800286,
    lng: -101.18245916021894
};

const style = [
    {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "2.00"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#9c9c9c"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#abba99"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#abba99"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "saturation": "-39"
            },
            {
                "color": "#a49384"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eeeeee"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7b7b7b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "saturation": "-31"
            },
            {
                "lightness": "-36"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c8d7d4"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#070707"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "visibility": "off"
            }
        ]
    }
]
const GoogleMaps = (props) => {
    // console.log(props)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBRVuQn2v-wD-fEOoFZLlqVZJhNwdyepKY"
    })
    const [res, setResponse] = useState(null)
    const lineSymbol = {
      path: 'M -2,-2 2,-2 2,2 -2,2 z', // 'M -2,0 0,-2 2,0 0,2 z',
      strokeColor: '#634F42',
      fillColor: '#634F42',
      fillOpacity: 1,
      scale: 2
    };
    const directionsCallback = (response) => {
        // console.log(response)
    
        if (response !== null) {
          if (response.status === 'OK') {
            setResponse(response)
            // this.setState(
            //   () => ({
            //     response
            //   })
            // )
          } else {
            // console.log('response: ', response)
          }
        }
        // console.log("res",res)
    }
    // console.log("res",res)
    return isLoaded ? ( 
            <GoogleMap
                id="mapa-rutas"
                mapContainerStyle={containerStyle}
                center={center}
                zoom={16}
                options={{styles:style}}
            >
                {
                    props.destination &&

                        <DirectionsService
                        options={{ 
                          destination: props.destination,
                          origin: props.origen,
                          travelMode: "DRIVING"
                        }}
                        // required
                        callback={directionsCallback}
                      />
                }
                {
                    res !== null && (
                        <DirectionsRenderer
                        // required
                        options={{ 
                            directions: res,
                            polylineOptions: {
                              icons: [
                                  {
                                      icon: lineSymbol,
                                      offset: '0',
                                      repeat: '15px',
                                  },
                              ],
                              path: [],
                              strokeOpacity: 0,
                              strokeColor: '#FF585D',
                            },
                        }}
                        />
                    )
                }
                <Marker  
                            icon={{url: "/assets/Logotipos e iconos/img-marker-zalika.png", anchor: new google.maps.Point(10, 50), scaledSize: new google.maps.Size(40, 50)}} 
                            position={{ lat: props.origen.lat, lng: props.origen.lng }} />
                { /* Child components, such as markers, info windows, etc. */ }
                <></>
            </GoogleMap>
     ) : <></>
}
 
export default GoogleMaps;