import React from 'react';
import styled from 'styled-components'

const Footer = () => {
    return ( 
        <>
         <Footers>
            <div>
                <img src="/assets/Logotipos e iconos/svg_lso_zalika.svg" alt="" />
                <p>Zálika 2022 | Todos los derechos reservados | Política de privacidad</p>
                <p>*Imágenes ilustrativas, el producto real puede presentar algunos cambios.</p>
                <p>Diseño web por:</p>
                <a target={'_blank'} href="https://renderinc.mx"><img className='renderinc' src="/assets/Logotipos e iconos/svg-logo-renderinc.svg" alt="" /></a>
            </div>
         </Footers>
        </>
    );
}

const Footers = styled.div`
    width: 100%;
    height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
            width: 80px;
            margin-bottom: 10px;
            @media (max-width:550px) {
                width: 70px;
            }
        }
        .renderinc{
            width: 80px;
        }
        p{
            color: #634F42;
            font-size: 15px;
            @media (max-width:550px) {
                text-align: center;
            }
            &:nth-child(3){
                font-size: 12px;
            }
            &:nth-child(4){
                font-size: 10px;
                color: #BCBCBC;
            }
        }
    }
`

export default Footer;