import React, { useState } from 'react';
import styled from 'styled-components'
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import swal from 'sweetalert';

const Formulario = (props) => {
    const [recaptcha, setRecaptcha] = useState("")
    const [errorRecaptcha, showError] = useState("ocultar-error")

    const onChange = (val) => {
        setRecaptcha(val)
    }
    const formik = useFormik({
        initialValues: {
          nombre: '',
          tel: '',
          email: '',
        },
        validationSchema: Yup.object({
            nombre: Yup.string().required('Campo requerido'),
            tel: Yup.string().min(10,'Deben ser mínimo 10 dígitos').max(10,'Deben ser máximo 10 dígitos').required('Campo requerido'),
            email: Yup.string().email('Correo invalido'),
        }),
        onSubmit: async values => {
            //console.log(values)
            const payload = {
                ...values,
                recaptcha: recaptcha 
            }
            if(recaptcha !== ''){
                const res = await axios.post('https://zalika.mx/php/index.php', payload)
                //console.log(res,'respuesta')
                if(res.data.success){
                    swal("Enviado", "El correo a sido enviado correctamente", "success");
                    var link = document.createElement('a');
                    link.href = '/assets/BOOK.pdf';
                    link.download = 'Brochure.pdf';
                    link.dispatchEvent(new MouseEvent('click'));
                    formik.resetForm();
                }else{
                    if(res.data.error){
                        swal("Error", "Ocurrió un problema al enviar el correo", "error");
                        formik.resetForm();
                    }
                }
            }else{
                showError('error')
            }
        },
      });
    return ( 
        <>
            <Form>
                <div className='contenedor_form'>
                    <p className='closed' onClick={()=>props.setShowModal(false)}>X</p>
                    <img src="/assets/Logotipos e iconos/svg_iso_zalika.svg" alt="" />
                    <h1>La grandeza de un Hogar en equilibrio</h1>
                    <p>Ingresa tus datos y descarga nuestro brochure</p>
                         <form onSubmit={formik.handleSubmit}>
                            <div className='row'>
                                <div className='column'>
                                    <input
                                        id="nombre"
                                        name="nombre"
                                        type="text"
                                        placeholder='Nombre'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.nombre}
                                    />
                                    {formik.touched.nombre && formik.errors.nombre ? (
                                        <div>{formik.errors.nombre}</div>
                                    ) : null}
                                </div>
                                <div className='column'>
                                    <input
                                        id="tel"
                                        name="tel"
                                        type="number"
                                        placeholder='Teléfono'
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.tel}
                                    />
                                    {formik.touched.tel && formik.errors.tel ? (
                                        <div>{formik.errors.tel}</div>
                                    ) : null}
                                </div>
                            </div>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                placeholder='Correo Eléctronico'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div>{formik.errors.email}</div>
                            ) : null} 
                            <span>*Al descargar brochure acepta nuestros términos de privacidad.</span>
                            <div className='row'>
                            <div className='recaptcha'>
                                <ReCAPTCHA
                                    sitekey="6LcfN0gjAAAAAAmlRHUbpmJY3XbjdTPrccSCuHxT"
                                    onChange={onChange}
                                />
                                <p className={`${errorRecaptcha}`}>Llene el recaptcha</p>
                                </div>
                                <button type="submit">Enviar</button>
                            </div>

                            </form>
                </div>
            </Form>
        </>
     );
}
 
const Form = styled.div`
width: 100%;
height: 100vh;
position: fixed;
z-index: 999;
background-color: rgba(0, 0, 0, 0.471);
overflow-y: auto;
.contenedor_form{
    background-color: #8A9E77;
    width: 40%;
    height: 100%;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    @media (min-width:1600px) {
        width: 35%;
        padding: 40px 60px;
    }
    @media (max-width:1024px) {
        width: 60%;
    }
    @media (max-width:768px) {
        width: 80%;
    }
    @media (max-width:550px) {
        width: 100%;
        min-height: 750px;
    }
    img{
        position: absolute;
        top: -50px;
        left: -50px;
        width: 350px;
    }
    h1{
        font-family: "Julius Sans One, Regular";
        color: white;
        font-size: 40px;
        padding-top: 115px;
        @media (max-width:550px) {
            text-align: center;
            font-size: 35px;
            padding-top: 20px;
        }
    }
    p{
        color: white;
        font-size: 20px;
        @media (max-width:550px) {
            text-align: center;
            font-size: 15px;
        }
    }
    span{
        color: white;
        font-size: 15px;
        @media (max-width:550px) {
            text-align: center;
            font-size: 12px;
        }
    }
    button{
        border: none;
        background-color:#C2B0A1;
        color: white;
        text-decoration: none;
        padding: 10px 20px;
        cursor: pointer;
        font-family: "Julius Sans One, Regular";
        &:hover{
            background-color: #C2B0A1;
        }
    }
    .closed{
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        font-size: 30px;
    }
    form{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 35%;
        @media (max-width:550px) {
            height: 60%;
            min-height: 420px;
            max-height: 420px;
        }
        div{
            color: red;
            font-size: 12px;
        }
        .row{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width:550px) {
                flex-direction: column;
                row-gap: 20px;
            }
            .column{
                width: 48%;
                @media (max-width:550px) {
                    width: 100%;
                }
            }
        }
        input{
            width: 100%;
            height: 40px;
            border: none;
            padding-left: 10px;
            color: #8A9E77;
            font-size: 16px;
            outline: none;
            &::placeholder{
                padding-left: 10px;
                color: #8A9E77;
                font-size: 16px;
            }
        }
        input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
-webkit-appearance: none;
margin: 0;
}

input[type=number] { -moz-appearance:textfield; }
    }
}
`
export default Formulario;