import React, {useCallback,useEffect,useState} from 'react';
import styled from 'styled-components'
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Link, useNavigate, useLocation , redirect} from "react-router-dom";

const Header = (props) => {
    const [y, setY] = useState(window.scrollY);
    const [scrollMenu, setScroll] = useState('')
    const [showLogo, setLogo] = useState('oculto');
    const navigate = useNavigate();
    const actualUrl = useLocation();
    const handleNavigation = useCallback(
        (e) => {
          const window = e.currentTarget;
          if (y > window.scrollY) {
              if(window.scrollY < 500){
                  setScroll('')
                  setLogo('oculto')
             }
          } else if (y < window.scrollY) {
              if(window.scrollY > 500){
                  setScroll('back_menu')
                  setLogo('')
              }
          }
          setY(window.scrollY);
        },
        [y]
    );
    const handleMenu = (type) =>{
        if(window.screen.width <= 1024)
        props.setOpen(props.open === 'menu-open' ? '' : 'menu-open')
        if (type) scrollToPage(type)
    }

    const galeria = (type) => {
        if(type !== "hash"){
            handleMenu()
        }
        props.setShowModalG(true)
    }
    const scrollTarget = (target) => { 
        //console.log(target)
        scroller.scrollTo(target, {smooth: true, duration: 700})
    };

    const scrollToPage =  (target) => {
            navigate(target)
            scrollTarget(target.slice(1));
        //}
    };

    useEffect(() => {
        setY(window.scrollY);
        window.addEventListener("scroll", handleNavigation);
        //console.log(actualUrl)
        if(actualUrl.hash==="#galeria"){
            galeria("hash")
            //console.log("entramos")
        }
        return () => {
          window.removeEventListener("scroll", handleNavigation);
        };
      }, [handleNavigation]);

    return ( 
        <Headers className={`header ${scrollMenu}`}>
            <div className='icon_menu'>
                <p className="btn-toggle" onClick={()=> handleMenu()} ><span></span></p>
            </div>
            <div className='logo_movil'>
                <img src="/assets/Logotipos e iconos/svg.logo.zalika.svg" alt="" />
            </div>
           <div className='logo'>
                <a className={`${showLogo}`} href="/"><img src="/assets/Logotipos e iconos/svg_lso_zalika.svg" alt="" /></a>
           </div>
            <nav>
                <Menu>
                    <li><a href="#inicio" onClick={()=> handleMenu("#inicio")}>INICIO</a></li>
                    <li><a href="#concepto" onClick={()=> handleMenu("#concepto")}>CONCEPTO</a></li>
                    <li><a href="#amenidades" onClick={()=> handleMenu("#amenidades")}>AMENIDADES</a></li>
                    <li><a onClick={()=>{galeria()}}>GALERÍA</a></li>
                    <li><a href="#ubicacion" onClick={()=> handleMenu("#ubicacion")}>UBICACIÓN</a></li>
                    <li><a href="/disponibilidad">DISPONIBILIDAD</a></li>
                    <li className='redes'>
                        <a target={'_blank'} className='tel' href="tel:+524432984527">T. 4432984527</a>
                        <a target={'_blank'} href="https://www.instagram.com/residencialzalika/"><img src="/assets/Logotipos e iconos/icon.instagram.zankia.svg" alt="" /></a>
                        <a target={'_blank'} href="https://www.facebook.com/ResidencialZÁLIKA"><img src="/assets/Logotipos e iconos/svg.icon.facebook.zankia.svg" alt="" /></a>
                    </li>
                    <li>
                        <a className='whatsapp' href="https://wa.me/+524431699498" target={'_blank'}><img src="/assets/Logotipos e iconos/svg-icon-whatsapp.svg" alt="" /></a>
                    </li>
                </Menu>
            </nav>
        </Headers>
     );
}
const Headers = styled.header`
    width: 100%;
    height: 80px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    z-index: 99;
    align-items: center;
    @media (max-width:1024px) {
        background-color: white;
        height: 150px;
        align-items: flex-start;
        padding-top: 30px;
        z-index: 99;
    }
    .icon_menu{
        display: none;
        @media (max-width:1024px) {
            display: flex;
            padding-left: 20px;
        }
    }
    .btn-toggle {
        width: 50px;
        height: 50px;
        margin-right: 0px !important;
        cursor: pointer;
        position: relative;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            height: 3.8px;
            width: 60%;
            background-color:  #634F42;
            transition: width .5s;
            &::before, &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color: #634F42;
                position: absolute;
                left: 0;
                transition: width .5s;
            }
    
            &::before {
                top: -8px;
            }
            &::after {
                bottom: -7px;
            }
            &::before, &::after {
                transition: all .3s;
            }
        }
    }
    .logo_movil{
        display: none;
        @media (max-width:1024px) {
            width: 100px;
            display: flex;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            img{
                width: 100%;
            }
        }
        @media (max-width:550px) {
            width: 85px;
        }
    }
    .logo{
        width: 30%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width:1024px) {
            display: none !important;
        }
        a{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        img{
            width: 80%;
            height: 80%;
            object-fit: contain;
        }
        }
    }
    .oculto{
        display: none !important;
    }
    nav{
        width: 70%;
        @media (max-width:1024px) {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            height: 84vh;
            background-color: white;
            transform: translateY(-1050px);
            transition: transform 0.5s ease;
            z-index: 3;
        }
        @media (max-width:550px) {
            transform: translateY(-900px);
        }
        ul{
            @media (max-width:1024px) {
                flex-direction: column;
                height: 80%;
            }

        }
    }
    &.back_menu{
        background-color: white;
    }
`

const Menu = styled.ul`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li{
        list-style: none;
        color: #7C7B77;
        font-family: "Julius Sans One, Regular";
        display: flex;
        align-items: center;
        justify-content: space-between;
        a{
            color: #8CA17D;
            text-decoration: none;
            display: flex;
            align-items: center;
            margin-left: 10px;
            font-size: 16px;
            cursor: pointer;
            @media (min-width:1600px) {
                    font-size: 20px;
                }
            &.tel{
                font-size: 18px;
                @media (min-width:1600px) {
                    font-size: 23px;
                }
            }
            img{
                height: 20px;
            }
            &:hover{
                color: #634F42;
            }
        }
        .whatsapp{
            padding: 8px 10px;
            background-color: #25D366;
            display: flex;
            img{
                width: 30px;
                height: auto;
            }
        }
        &.redes{
            width: 170px;
            @media (min-width:1600px) {
                width: 220px;
            }
            img{
                height: 18px;
            }
        }
    }
`
 
export default Header;