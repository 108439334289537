import React from 'react';
import styled from 'styled-components'
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {Link, useLocation, useNavigate } from "react-router-dom";
// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
const Galeria = (props) => {
    const actualUrl = useLocation();
    const history = useNavigate();
    const handlehash = () =>{
        if(actualUrl.hash === "#galeria")
        history("#inicio")
    }
    return ( 
        <>
            <Galerias>
                <p className='closed' onClick={()=>props.setShowModal(false)}>X</p>
                <div className='carousel'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    onDestroy={()=> handlehash()}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='imagen'>
                            <img src="/assets/Galeria/1.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='imagen'>
                            <img src="/assets/Galeria/2.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='imagen'>
                            <img src="/assets/Galeria/3.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='imagen'>
                            <img src="/assets/Galeria/4.jpg" alt="" />
                        </div>
                    </SwiperSlide>
                </Swiper>
                </div>
            </Galerias>
        </>
     );
}

const Galerias = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.471);
    display: flex;
    justify-content: center;
    align-items: center;
    .closed{
        position: absolute;
        right: 50px;
        top: 50px;
        cursor: pointer;
        font-size: 30px;
        color: white;
        @media (max-width:550px) {
            right: 20px;
            top: 120px;
        }
    }
    .swiper-button-next:after, .swiper-button-prev:after{
        color: white;
    }
    .swiper{
        width: 100%;
        height: 100%;
    }
    .carousel{
        width: 80%;
        height: 90%;
        @media (max-width:550px) {
            width: 95%;
            height: 50%;
        }
        .imagen{
            height: 100%;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            
        }
    }
`
export default Galeria;