import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import styled from 'styled-components'
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";
const Prototipos = (props) => {
    return ( 
        <>
            <Prototipo>
                <p className='closed' onClick={()=>props.setShowModal(false)}>X</p>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='prototipo'>
                           <div className='fachada'>
                                <img src="/assets/Prototipos/01.Zazil/optimizadas/01.img-ext-zazil-min.jpg" alt="" />
                           </div>
                            <div className='detalles'>
                                <div className='detalles_center'>
                                    <img src="/assets/Prototipos/SVG/zazil.svg" alt="" />
                                    <p>Lote tipo 6.50 m x 18 m 157.7 m<sup>2</sup> de construcción</p>
                                    <div className='plantas'>
                                        <span className='activo'>Planta Baja</span> <span>Planta Alta</span>
                                    </div>
                                    <div className='detalles_lista'>
                                        <ul>
                                            {/* <li>Sala</li> */}
                                            <li>1 Baño</li>
                                            <li>Comedor</li>
                                            <li>Patio de servicio</li>
                                            <li>Cocina integral</li>
                                            <li>Jardín trasero</li>
                                            <li>Barra desayunadora</li>
                                            <li>Dos espacios de cochera</li>
                                            <li>1 Recamara</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='planta'>
                                <img src="/assets/Prototipos/01.Zazil/optimizadas/cocina.png" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='prototipo'>
                           <div className='fachada'>
                                <img src="/assets/Prototipos/01.Zazil/optimizadas/01.img-ext-zazil-min.jpg" alt="" />
                           </div>
                            <div className='detalles'>
                                <div className='detalles_center'>
                                    <img src="/assets/Prototipos/SVG/zazil.svg" alt="" />
                                    <p>Lote tipo 6.50 m x 18 m 157.7 m<sup>2</sup> de construcción</p>
                                    <div className='plantas'>
                                        <span >Planta Baja</span> <span className='activo'>Planta Alta</span>
                                    </div>
                                    <div className='detalles_lista'>
                                        <ul className='width-full'>
                                            <li>3 Recámara</li>
                                            <li>1 Vestidor</li>
                                            <li>2 Baños</li>
                                            <li>Calentador solar</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='planta'>
                                <img src="/assets/Prototipos/01.Zazil/optimizadas/02.zazil-2-min.png" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='prototipo'>
                           <div className='fachada'>
                                <img src="/assets/Prototipos/02.Zinia/optimizadas/01.img-ext-zinia-min.jpg" alt="" />
                           </div>
                            <div className='detalles'>
                                <div className='detalles_center'>
                                    <img src="/assets/Prototipos/SVG/zinia.svg" alt="" />
                                    <p>178.34 m<sup>2</sup> de construcción</p>
                                    <div className='plantas'>
                                        <span className='activo'>Planta Baja</span> <span>Planta Alta</span>
                                    </div>
                                    <div className='detalles_lista'>
                                        <ul>
                                            <li>Sala</li>
                                            <li>Comedor</li>
                                            <li>Cocina integral</li>
                                            <li>Barra desayunadora</li>
                                            <li>1/2 Baño</li>
                                            <li>Jardín trasero</li>
                                            <li>Dos espacios de cochera</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='planta'>
                                <img src="/assets/Prototipos/02.Zinia/optimizadas/03.zinia-1-min.png" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='prototipo'>
                           <div className='fachada'>
                                <img src="/assets/Prototipos/02.Zinia/optimizadas/01.img-ext-zinia-min.jpg" alt="" />
                           </div>
                            <div className='detalles'>
                                <div className='detalles_center'>
                                    <img src="/assets/Prototipos/SVG/zinia.svg" alt="" />
                                    <p>178.34 m<sup>2</sup> de construcción</p>
                                    <div className='plantas'>
                                        <span >Planta Baja</span> <span className='activo'>Planta Alta</span>
                                    </div>
                                    <div className='detalles_lista'>
                                        <ul className='width-full'>
                                            <li>3 Recámara</li>
                                            <li>1 Vestidor</li>
                                            <li>3 Baños</li>
                                            <li>1 Balcón</li>
                                            <li>Calentador solar</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='planta'>
                                <img src="/assets/Prototipos/02.Zinia/optimizadas/04.zinia-2-min.png" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='prototipo'>
                           <div className='fachada'>
                                <img src="/assets/Prototipos/03.Zury/optimizadas/01.img-ext-zury-min.jpg" alt="" />
                           </div>
                            <div className='detalles'>
                                <div className='detalles_center'>
                                    <img src="/assets/Prototipos/SVG/zury.svg" alt="" />
                                    <p>160.97 m<sup>2</sup> de construcción</p>
                                    <div className='plantas'>
                                        <span className='activo'>Planta Baja</span> <span>Planta Alta</span>
                                    </div>
                                    <div className='detalles_lista'>
                                        <ul>
                                            <li>Sala</li>
                                            <li>Comedor</li>
                                            <li>Cocina integral</li>
                                            <li>Barra desayunadora</li>
                                            <li>1/2 Baño</li>
                                            <li>Jardín trasero</li>
                                            <li>Dos espacios de cochera</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='planta'>
                                <img src="/assets/Prototipos/03.Zury/optimizadas/05.zury-1-min.png" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='prototipo'>
                           <div className='fachada'>
                                <img src="/assets/Prototipos/03.Zury/optimizadas/01.img-ext-zury-min.jpg" alt="" />
                           </div>
                            <div className='detalles'>
                                <div className='detalles_center'>
                                    <img src="/assets/Prototipos/SVG/zury.svg" alt="" />
                                    <p>160.97 m<sup>2</sup> de construcción</p>
                                    <div className='plantas'>
                                        <span >Planta Baja</span> <span className='activo'>Planta Alta</span>
                                    </div>
                                    <div className='detalles_lista'>
                                        <ul className='width-full'>
                                            <li>3 Recámara</li>
                                            <li>1 Vestidor</li>
                                            <li>2 Baños</li>    
                                            <li>Calentador solar</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='planta'>
                                <img src="/assets/Prototipos/03.Zury/optimizadas/06.zury-2-min.png" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='prototipo'>
                           <div className='fachada'>
                                <img src="/assets/Prototipos/04.Zazu/optimizadas/02.img-cc-zazu-min.jpg" alt="" />
                           </div>
                            <div className='detalles'>
                                <div className='detalles_center'>
                                    <img src="/assets/Prototipos/SVG/zazu.svg" alt="" />
                                    <p className='padding-metros'>259.1 m<sup>2</sup> de construcción</p>
                                    <div className='plantas min-width'>
                                        <span className='activo'>Planta Baja</span> <span>Planta Alta</span><span>Azotea</span>
                                    </div>
                                    <div className='detalles_lista'>
                                        <ul>
                                            <li>Sala</li>
                                            <li>Comedor</li>
                                            <li>Cocina integral</li>
                                            <li>Barra desayunadora</li>
                                            <li>1 Recámara</li>
                                            <li>Patio de servicio</li>
                                            <li>1 1/2 Baño</li>
                                            <li>2 Jardines</li>
                                            <li>Dos espacios de cochera</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='planta'>
                                <img src="/assets/Prototipos/04.Zazu/optimizadas/07.zazu.-1-min.png" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='prototipo'>
                           <div className='fachada'>
                                <img src="/assets/Prototipos/04.Zazu/optimizadas/02.img-cc-zazu-min.jpg" alt="" />
                           </div>
                            <div className='detalles'>
                                <div className='detalles_center'>
                                    <img src="/assets/Prototipos/SVG/zazu.svg" alt="" />
                                    <p className='padding-metros'>259.1 m<sup>2</sup> de construcción</p>
                                    <div className='plantas min-width'>
                                        <span >Planta Baja</span> <span className='activo'>Planta Alta</span><span>Azotea</span>
                                    </div>
                                    <div className='detalles_lista bottom-zazu-1'>
                                        <ul className='width-full'>
                                            <li>3 Recámara</li>
                                            <li>1 Vestidor</li>
                                            <li>3 Baños</li>    
                                            <li>1 Balcón</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='planta'>
                                <img src="/assets/Prototipos/04.Zazu/optimizadas/08.zazu.-2-min.png" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='prototipo'>
                           <div className='fachada'>
                                <img src="/assets/Prototipos/04.Zazu/optimizadas/02.img-cc-zazu-min.jpg" alt="" />
                           </div>
                            <div className='detalles'>
                                <div className='detalles_center'>
                                    <img src="/assets/Prototipos/SVG/zazu.svg" alt="" />
                                    <p className='padding-metros'>259 m<sup>2</sup> de construcción</p>
                                    <div className='plantas min-width'>
                                        <span >Planta Baja</span> <span>Planta Alta</span><span className='activo'>Azotea</span>
                                    </div>
                                    <div className='detalles_lista bottom-zazu-2'>
                                        <ul className='width-full '>
                                            <li>Roof garden</li>
                                            <li>1/2 Baño</li> 
                                            <li>Calentador solar</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='planta'>
                                <img src="/assets/Prototipos/04.Zazu/optimizadas/09.zazu.-3-min.png" alt="" />
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Prototipo>
        </>
     );
}
const Prototipo = styled.div`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 50px 10px;
    overflow-y: scroll;
    @media (max-width:550px) {
        padding: 60px 10px;
    }
    .swiper-button-next:after, .swiper-button-prev:after{
        color: #634F42;
    }
    .swiper-button-next{
        right: 0 !important;
    }
    .swiper-button-prev{
        left: 0 !important;
    }
    .closed{
        color: #8A9E77;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        font-size: 30px;
    }
    .prototipo{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 30% 20% 50%;
        @media (max-width:1024px) {
            grid-template-columns: 100%;
            grid-template-rows: 500px 500px 700px;
        }
        @media (max-width:550px) {
            grid-template-columns: 100%;
            grid-template-rows: 300px auto 300px;
          
        }
        .fachada{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media (max-width:1024px) {
                justify-content: center;
            }
            img{
                width: 85%;
                height: 450px;
                object-fit: cover;
                @media (max-width:1024px) {
                    object-fit: contain;
                }
                @media (max-width:550px) {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .detalles{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 50px;
            position: relative;
            @media (max-width:550px) {
               padding-left: 0;
            }
            .detalles_center{
                position: relative;
                height: 450px;
                @media (max-width:1024px) {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 30px 0;
                    margin-bottom: 30px;
                }
                @media (max-width:550px) {
                    margin-bottom: 0;
                }
                p{
                    font-family: "Catamaran, SemiBold";
                    color: #634F42;
                    font-size: 18px;
                    width: 80%;
                    padding: 30px 0;
                    @media (max-width:1024px) {
                        width: auto;
                        text-align: center;
                    }
                    @media (max-width:550px) {
                        width: 80%;
                    }
                }
                .padding-metros{
                    padding: 40px 0;
                }
                img{
                    width: 200px;
                    @media (max-width:1024px) {
                        padding-top: 30px;
                    }
                    @media (max-width:550px) {
                        width: 130px;
                    }
                }
                .plantas{
                    width: 80%;
                    display: flex;
                    justify-content: space-between;
                    @media (max-width:1024px) {
                        width: 40%;
                    }
                    @media (max-width:550px) {
                       width: 70%;
                    }
                    span{
                        font-size: 18px;
                        color: #634F42;
                        font-family: "Catamaran, SemiBold";
                    }
                    .activo{
                        border-bottom: 2px solid #634F42 ;
                    }
                }
                .min-width{
                    min-width: 290px;
                }
                .detalles_lista{
                    width: 450px;
                    padding-left: 20px;
                    position: absolute;
                    bottom: 10px;
                    @media (max-width:1024px) {
                        width: 60%;
                    }
                    @media (max-width:550px) {
                        margin: 30px 0;
                        width: 75%;
                        position: relative;
                        bottom: 0 !important;
                    }
                    ul{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        @media (max-width:550px) {
                            column-gap: 10px;
                        }
                        li{
                            width: 50%;
                            color: #634F42;
                            font-size: 17px;
                            @media (max-width:550px) {
                                font-size: 15px;
                                width: 47%;
                            }
                        }
                    }
                    .width-full{
                        flex-wrap: nowrap;
                        flex-direction: column;
                    }
                    &.bottom-zazu-1{
                        bottom: 40px;
                    }
                    &.bottom-zazu-2{
                        bottom: 80px;
                    }
                }
            }
        }
        .planta{
            width: 100%;
            height: 100%;
            @media (max-width:1024px) {
                grid-row: 3/4;
                display: flex;
                justify-content: center;
            }
            img{
                width: 75%;
                height: auto;
                object-fit: contain;
            }
        }
    }
`
export default Prototipos;