import React, { useState } from 'react';
import './style.css'
const Slider = () => {
    const [currrentPointer, setCurrentPointer] = useState(1)

    const handleCheck = () => {
        if (currrentPointer === 7) {
            setCurrentPointer(1)
            document.getElementById(`item-${currrentPointer}`).checked = true
            return
        }
        document.getElementById(`item-${currrentPointer}`).checked = true
        // increments pointer
        setCurrentPointer(currrentPointer+1)
        // Array.from(7).forEach((element, index) => {
        //     const currentElement = document.getElementById(`item-${index}`).checked = true
        // })

    }

    setTimeout(() => {
        handleCheck()
        //console.log('cambie el pointer: ', currrentPointer)
    }, 4000)


    return ( 
        <>
            <div className='container'>
                <input type="radio" name="slider" id="item-1" defaultChecked />
                <input type="radio" name="slider" id="item-2" />
                <input type="radio" name="slider" id="item-3" />
                <input type="radio" name="slider" id="item-4" />
                <input type="radio" name="slider" id="item-5" />
                <input type="radio" name="slider" id="item-6" />
                <input type="radio" name="slider" id="item-7" />
                <div className="cards">
                    <label className="card" htmlFor="item-1" id="song-1">
                        <div className='amenidad'>
                            <img src="/assets/Logotipos e iconos/svg_ornamento3_zalika.svg" alt="" />
                            <h2>ACCESO CONTROLADO</h2>
                        </div>
                        <img src="/assets/Galeria_amenidades/acceso_controlado.jpg" alt="Acceso controlado" />
                    </label>
                    <label className="card" htmlFor="item-2" id="song-2">
                        <div className='amenidad'>
                            <img src="/assets/Logotipos e iconos/svg_ornamento3_zalika.svg" alt="Gimnacio" />
                            <h2>GIMNASIO EXTERIOR</h2>
                        </div>
                        <img src="/assets/Galeria_amenidades/gimnasio_exterior.jpg" alt="Trotapista"/>
                    </label>
                    <label className="card" htmlFor="item-3" id="song-3">
                        <div className='amenidad'>
                            <img src="/assets/Logotipos e iconos/svg_ornamento3_zalika.svg" alt="" />
                            <h2>TROTAPISTA</h2>
                        </div>
                        <img src="/assets/Galeria_amenidades/trotapista.jpg" alt="song" />
                    </label>
                    <label className="card" htmlFor="item-4" id="song-4">
                        <div className='amenidad'>
                            <img src="/assets/Logotipos e iconos/svg_ornamento3_zalika.svg" alt="" />
                            <h2>JUEGOS INFANTILES</h2>
                        </div>
                        <img src="/assets/Galeria_amenidades/juegos_infantiles.jpg" alt="song" />
                    </label>
                    <label className="card" htmlFor="item-5" id="song-5">
                        <div className='amenidad'>
                            <img src="/assets/Logotipos e iconos/svg_ornamento3_zalika.svg" alt="" />
                            <h2>FOGATERO</h2>
                        </div>
                        <img src="/assets/Galeria_amenidades/fogatero.jpg" alt="song" />
                    </label>
                    <label className="card" htmlFor="item-6" id="song-6">
                        <div className='amenidad'>
                            <img src="/assets/Logotipos e iconos/svg_ornamento3_zalika.svg" alt="" />
                            <h2>ARENERO</h2>
                        </div>
                        <img src="/assets/Galeria_amenidades/arenero.jpg" alt="song" />
                    </label>
                    <label className="card" htmlFor="item-7" id="song-7">
                        <div className='amenidad'>
                            <img src="/assets/Logotipos e iconos/svg_ornamento3_zalika.svg" alt="" />
                            <h2>VIGILANCIA 24/7</h2>
                        </div>
                        <img src="/assets/Galeria_amenidades/vigilancia_24_hrs.jpg" alt="song" />
                    </label>
                </div>
             </div>
      </>
     );
}
 
export default Slider;